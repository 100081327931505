import React, { useState, useEffect, useRef } from "react";
import styles from './styles.module.css';
import sharedstyles from '../shared/shared-styles.module.css';
import Container from 'react-bootstrap/Container';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getSignedUrl } from '../../utils/amplify'
import Spinner from '../../components/Spinner'
import Button from 'react-bootstrap/Button'

export interface returnUrl {
  href: string;
}

export interface ISocketResponse {
  payloadUri: string;
  returnUrl: returnUrl;
}

export interface payloadToItt {
  payloadUri: string
  simulation: string
}

interface ILocationState {
  givenSignature: string;
  givenpayloadUri: string;
}

export const LoadingScreen: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { givenSignature = '', givenpayloadUri = '' } = location.state as ILocationState;
  const [socketClosed, setSocketClosed] = useState<boolean>(false)
  const [messageData, setMessageData] = useState<ISocketResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const socketRef = useRef<WebSocket | null>(null);

  function generateWebSocket(signedUrl: string){
      if (signedUrl.length) {
        socketRef.current = new WebSocket(`${signedUrl}?payloadSignature=${givenSignature}&payloadUri=${givenpayloadUri}`);
        if (socketClosed && socketRef.current) {
          setSocketClosed(false)
        }

        try {
          socketRef.current.onopen = () => {
            let paymentURI: payloadToItt = {
              payloadUri: givenpayloadUri,
              simulation: params["choice"] ?? ""
            };

            // Send data through the WebSocket after the connection is established and the $connect event has returned a 200 status code
            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
              socketRef?.current?.send(JSON.stringify(paymentURI));
            }
          }
        } catch (e) {
          console.error('An error occured initializing the Payment:', e);
          setErrorMessage('An error occured initializing the Payment');
        }

        // need to handle this still
        socketRef.current.onerror = (event) => {
          console.error("A general error occurred with the WebSocket:", event)
          setErrorMessage('An error occurred with the WebSocket');
        }

        // wait for message from ITT
        socketRef.current.onmessage = (event) => {
          try {
            const message: ISocketResponse = JSON.parse(event.data);
            setMessageData(message);
          } catch (e) {
            console.error("An error occurred while parsing the message:", e)
            setErrorMessage('An error occurred while parsing the message');
          }
        }

        socketRef.current.onclose = (event) => {
          setSocketClosed(true)
        }
    }
    
  }

  function handleRetry() {
    setProgress(0)
    socketRef.current?.close()
    getSignedUrl().then((signedUrl) => generateWebSocket(signedUrl))
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 50);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (messageData && messageData.returnUrl) {
      if (messageData.returnUrl.href.includes('null')) {
        setErrorMessage('Paymant already existed, returned Null')
      } else {
        window.location.href = messageData.returnUrl.href;
      }
    }
  }, [messageData]);

  useEffect(() => {
    if (!Boolean(givenpayloadUri) || !Boolean(givenSignature)) {
      setErrorMessage('You did not provide a correct payload or signature to parse');
      return;
    }

    getSignedUrl()
      .then((signedUrl) => generateWebSocket(signedUrl))
  }, []);

  return (
    <Container fluid="xxl" className={sharedstyles.container}>
      <div className={sharedstyles.center}>
        {errorMessage ? (
          <>
            <div>{errorMessage}</div>
            <div><span><b>Provided payloadUri</b>: <br />{givenpayloadUri} </span></div>
            <div className={styles.signaturecontainer}> <span><b>Provided signature</b>:<br /> {givenSignature} </span></div>
            <Button
              className={sharedstyles.button}
              size="lg"
              onClick={() => navigate(`/?payloadUri=${givenpayloadUri}&sig=${givenSignature}`)}
            >Go back to start</Button>
          </>
        ) : (
          <div className={styles.center}>
            <h1>Waiting for full response</h1>
            <div>
              You called the: <b>{params["choice"]}</b> flow
            </div>
            <div >
              <Spinner />
            </div>
            <div>
              <span>Waiting for ITT</span>
            </div>
            <div>
              <span>Time elapsed: <b>{progress}</b> ms</span>
            </div>
            <p></p>
            <div>
            <Button
                        className={sharedstyles.button}
                        size="sm"
                        onClick={() => handleRetry()}
                        style={{visibility: progress >= 300 ? "visible" : "hidden"}}
                    >Retry action</Button>
            </div>
          </div>
        )
        }
      </div>
    </Container >
  );
}
